<template>
  <div>
    <v-card>
      <v-card-title
        class="justify-center"
        style="
          background-color: #006a4e;

          color: aliceblue;
        "
      >
        MTD SALES BY AREA</v-card-title
      >
      <v-layout row-wrap>
        <v-flex xs3 md3>
          <v-autocomplete
            :items="Allarea"
            v-model="AreaId"
            color="white"
            item-text="city"
            label="المنطقة"
            item-value="city"
            required
          ></v-autocomplete>
        </v-flex>
        <v-flex xs3 md3>
          <v-select
            v-model="month"
            :items="months"
            label="الشهر"
            persistent-hint
            required
          ></v-select>
        </v-flex>

        <v-flex xs3 md3>
          <v-select
            v-model="year"
            :items="years"
            label="السنة"
            persistent-hint
            required
          ></v-select>
        </v-flex>
      </v-layout>
      <v-container>
        <v-row>
          <v-col cols="6" sm="6" md="6">
            <apexchart
              :series="series"
              width="1000"
              :options="options"
            ></apexchart>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
// import AreaDetails1 from './area-details.vue';
// import areadetails from "./areadetails.vue";
import moment from "moment";
export default {
  name: "market-segmentation",

  components: {
    // areadetails
  },

  data() {
    return {
      Allarea: [],
      AreaId: "",
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      month: 0,
      years: [],
      year: 0,
      data: [],
      options: {
        dataLabels: {
          enabled: true,
        },
        chart: {
          width: 1000,
          height: 350,
          type: "line",
        },

        xaxis: {
          type: "datetime",
          categories: [],
        },
      },
    };
  },

  computed: {
    series() {
      let series = [
        {
          name: "Sales",
          data: this.data,
        },
      ];
      return series;
    },
  },
  watch: {
    AreaId() {
      this.getSalesByArea();
    },
    // month() {
    //   this.getSalesByArea();
    // },
    // year() {
    //   this.getSalesByArea();
    // },
  },

  created() {
    var d = new Date();

    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    this.getYears();
    this.getAllArea();
  },

  methods: {
    getYears() {
      let y = 0;
      while (y <= 5) {
        this.years.push(this.year + y);
        y++;
      }
    },

    async getAllArea() {
      const response = await this.ApiService.get(
        "https://salestools.yamamacement.com/api/getreportService/getAllAreaes"
      );
      this.Allarea = response.data.responseData;
      this.Allarea.push({ city: "total"});
    },
    async getSalesByArea() {
      this.data = [];
      let startDate = new Date(this.year + "-" + this.month + "-02")
        .toISOString()
        .substr(0, 10);
      let endDate = new Date(this.year + "-" + this.month + "-28")
        .toISOString()
        .substr(0, 10);

      startDate = moment(startDate).format("MM/DD/YYYY")
      endDate = moment(endDate).format("MM/DD/YYYY")

      let url = `https://salestools.yamamacement.com/api/getreportService/getSalesByArea?fromDate=${startDate}&toDate=${endDate}&area=${this.AreaId}`;

      const response = await this.ApiService.get(url);

      const sortedAsc = response.data.responseData.sort(
        (objA, objB) =>
          Number(new Date(objA.deliveryNoteDate)) -
          Number(new Date(objB.deliveryNoteDate))
      );

      sortedAsc.forEach((el) => {
        this.data.push(el.quantity);

        this.options.xaxis.categories.push(el.deliveryNoteDate);
      });

      // this.options.xaxis.categories.sort();
    },
  },
};
</script>

<style scoped>
.flex.xs3.md3 {
  padding: 40px;
}
.flex.xs3.md3 {
    margin: 10px;
}
.row.tables {
  margin-top: 59px;
}
</style>
